<template>
  <div class="footers">
    <div class="footers-img">
      <img
        src="https://yaimerfileoss.oss-cn-shanghai.aliyuncs.com/static/yaimer_static/footer.webp"
        alt="底部img"
      />
    </div>
    <div class="footers-inner">
      <p class="fs" style="position: relative">
        ～with you～<span class="faces fs">ﾉ(●'◡'●)ﾉ♥</span>
      </p>
      <p class="fs">
        Power By CHANGWP 已诞生:<i class="times">{{ time }}</i>
      </p>
      <p class="fs">
        <a class="fs" target="_blank" href="https://beian.miit.gov.cn">
          湘ICP备2021000577
        </a>
        <a
          class="fs"
          target="_blank"
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43092102000131"
        >
          <img
            src="https://oss.yaimer.com/static/img/beian.png"
            alt="备案图标"
          />
          湘公网安备 43092102000131
        </a>
      </p>
      <p class="fs">©2021-2099 本站版权归Yaimer所有</p>
    </div>
    <div
      id="fish"
      style="height: 300px; width: 100%; overflow: hidden; min-width: 300px"
    ></div>
  </div>
</template>

<script>
import "./../../assets/fish.js";

export default {
  data() {
    return {
      time: "52天13小时14分0秒",
    };
  },
  mounted() {
    const that = this;
    setInterval(function change() {
      that.time = that.common.changetime(new Date("2021/4/20 05:20:13"));
    }, 1000);
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.footers {
  width: 100%;
  min-width: 300px;
  position: relative;
  bottom: 0;
  margin-top: 10px;
}

.footers-img {
  position: absolute;
  width: 100%;
  bottom: 66px;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.footers .fs {
  font-size: 14px;
  color: grey;
  margin-top: 5px;
  font-weight: 600;
}

.faces {
  position: absolute;
  animation: myfirst 1s infinite;
  top: 0;
}
.footers a:hover {
  color: white;
}

.footers-inner {
  position: relative;
  bottom: -300px;
  padding: 20px 0px;
  z-index: 2;
  text-align: center;
}
@keyframes myfirst {
  0% {
    top: 0px;
  }

  12.50% {
    top: -2px;
  }

  25% {
    top: -4px;
  }

  37.50% {
    top: -6px;
  }

  50% {
    top: -8px;
  }

  62.50% {
    top: -6px;
  }

  75% {
    top: -4px;
  }

  87.50% {
    top: -2px;
  }

  100% {
    top: 0px;
  }
}
</style>