<template>
  <el-menu
    :default-active="this.$route.path"
    :class="this.$store.getters.isPCM ? 'PcM el-menu-demo' : 'el-menu-demo'"
    :router="true"
    :mode="this.$store.getters.isPCM ? 'vertical' : 'horizontal'"
    :collapse="this.$store.getters.openSlider"
    :collapse-transition="false"
    background-color="#20222A"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <el-menu-item index="/index/1">
      <template slot="title">
        <Icon :name="'menu1'" />
        <span>首页</span>
      </template>
    </el-menu-item>

    <el-menu-item index="/archives">
      <template slot="title">
        <Icon :name="'achives'" />
        <span>归档</span>
      </template>
    </el-menu-item>

    <el-menu-item @click="redirect">
      <template slot="title">
        <Icon :name="'menu2'" />
        <span>总览</span>
      </template>
    </el-menu-item>

    <el-menu-item index="/message">
      <template slot="title">
        <Icon :name="'message'" />
        <span>留言板</span>
      </template>
    </el-menu-item>

    <el-submenu index="/about">
      <template slot="title">
        <Icon :name="'about'" />
        <span>关于</span>
      </template>
      <el-menu-item index="/about/me">me</el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
import Icon from "./../iconSvg.vue";

export default {
  components: {
    Icon,
  },
  methods: {
    redirect() {
      window.location.href = "https://yaimer.com";
    },
  },
};
</script>

<style scoped>
.el-menu {
  border: none;
}
.PcM {
  margin-top: 30px;
}
</style>