<template>
  <el-container>
    <el-backtop></el-backtop>
    <el-header
      :style="
        this.$store.getters.isPCM
          ? 'box-shadow: 0px 5px 10px 10px rgb(0 0 0 / 10%);'
          : ''
      "
    >
      <Head />
    </el-header>

    <el-main>
      <Swiper />
      <el-row type="flex" justify="center" class="show">
        <el-col :xs="23" :sm="22" class="container">
          <transition name="el-fade-in" mode="out-in">
            <router-view :key="this.$route.path" />
          </transition>
        </el-col>
      </el-row>
    </el-main>

    <el-footer>
      <Footer />
    </el-footer>
  </el-container>
</template>

<script>
import './../../assets/css/blog.css'
import "./../../assets/mouth.js";
import Head from "./head.vue";
import Footer from "./footer.vue";
import Swiper from "./swiper.vue";

export default {
  components: {
    Head,
    Footer,
    Swiper,
  },
  created() {
    this.$router.afterEach(function () {
      window.scrollTo(0, 0);
    });
  },
};
</script>

<style scoped>
.el-header {
  background: #20222a;
  color: #333;
  line-height: 60px;
  padding: 0 !important;
  width: 100%;
  position: fixed;
  z-index: 99;
  overflow: hidden;
}

.el-footer {
  height: auto !important;
  padding: 0;
}
.el-main {
  margin: 60px 0 0 0;
  color: #333;
  padding: 0 !important;
  overflow: hidden;
}
.show {
  min-height: 500px;
  margin-top: 15px;
}

.container {
  max-width: 850px;
}
body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.el-backtop {
  right: 30px !important;
  bottom: 130px !important;
}
</style>