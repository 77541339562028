$(function () {
    var clickNum = 0;
    $("html").click(function (e) {
        //创建颜色库 随机拼接 前中后
        const frontColorLibrary = new Array('00', '11', '22', '33', '44', '55', '66', '77', '88', '99',
            'aa', 'bb', 'cc', 'dd', 'ee', 'ff');
        const inColorLibrary = frontColorLibrary
        const afterColorlibrary = frontColorLibrary
        //从颜色库选取一种颜色;当然这是数组的方式;随机性
        var frontColor = Math.floor(Math.random() * frontColorLibrary.length);
        var inColor = Math.floor(Math.random() * inColorLibrary.length);
        var afterColor = Math.floor(Math.random() * afterColorlibrary.length);
        const library = new Array('素胚勾勒出青花笔锋浓转淡', '瓶身描绘的牡丹一如你初妆', '冉冉檀香透过窗心事我了然', '宣纸上走笔至此搁一半',
            '釉色渲染仕女图韵味被私藏', '而你嫣然的一笑如含苞待放', '你的美一缕飘散', '去到我去不了的地方', '天青色等烟雨', '而我在等你', '炊烟袅袅升起',
            '隔江千万里', '在瓶底书汉隶仿前朝的飘逸', '就当我为遇见你伏笔', '天青色等烟雨', '而我在等你', '月色被打捞起', '晕开了结局', '如传世的青花瓷自顾自美丽',
            '你眼带笑意', '色白花青的锦鲤跃然于碗底', '临摹宋体落款时却惦记着你', '你隐藏在窑烧里千年的秘密', '极细腻犹如绣花针落地', '帘外芭蕉惹骤雨门环惹铜绿',
            '而我路过那江南小镇惹了你', '在泼墨山水画里', '你从墨色深处被隐去', '天青色等烟雨', '而我在等你', '炊烟袅袅升起', '隔江千万里', '在瓶底书汉隶仿前朝的飘逸',
            '就当我为遇见你伏笔', '天青色等烟雨', '而我在等你', '月色被打捞起', '晕开了结局', '如传世的青花瓷自顾自美丽', '你眼带笑意');
        var newSpan = $("<span>").text("❤" + library[clickNum] + "❤");
        clickNum++;
        if (clickNum==library.length) {
            clickNum=0;
        }
        $("html").append(newSpan);

        var xNum = e.pageX;
        var yNum = e.pageY;
        newSpan.css({
            "z-index": 999, //设置或获取定位对象的堆叠次序(z-index):999
            "top": yNum - 20, //上(top):y-20
            "left": xNum, //左:x
            "position": "absolute", //定位:绝对定位
            "font-weight": "bold", //字体粗细:粗体
            "color": "#" + frontColorLibrary[frontColor] + inColorLibrary[inColor] + afterColorlibrary[afterColor], //颜色:绿色
            "user-select": "none", //使文字不被选中
        });
        newSpan.animate({
            "top": yNum - 180, //上:y-180
            "opacity": 0 //透明度(opacity):0
        }, 2500, //1500,调节动画速度
            function () { //功能函数
                newSpan.remove(); //$i的删除
            }
        );

    })
})
import $ from 'jquery'