<template>
  <el-row type="flex" justify="center">
    <!-- 移动端时Nav -->
    <el-col :xs="24" :sm="0" class="PCMNav">
      <el-button
        type="primary"
        @click="changeOpenSlider(!$store.getters.openSlider)"
        class="hidden-sm-and-up"
      >
        <i class="el-icon-s-unfold"></i>
      </el-button>

      <span class="PCM_img">
        <img src="https://wp.yaimer.com/img/reddit.png" alt="hea" />
      </span>
    </el-col>

    <!-- 移动端遮罩 -->
    <div
      v-if="!this.$store.getters.openSlider && this.$store.getters.isPCM"
      class="drawer-bg"
      @click="changeOpenSlider(true)"
    />

    <!-- PC时 宽度100 最大850 PCM时宽度201 -->
    <el-col
      :class="
        this.$store.getters.isPCM
          ? this.$store.getters.openSlider
            ? 'closeAside PcMAside'
            : 'PcMAside'
          : 'PcCol'
      "
    >
      <Slider :key="this.$store.getters.isPCM" />
    </el-col>

    <!-- yaimer--------------------------- -->
      <el-link class="name"  type="primary" href="/admin">YAIMER'S</el-link>
  </el-row>
</template>

<script>
import Slider from "./slider.vue";

export default {
  components: {
    Slider,
  },
  methods: {
    changeOpenSlider(t) {
      this.$store.commit("openSlider", t);
    },
    toAdmin(){
      window.location="/admin/";
    }
  },
  watch: {
    $route() {
      //点击导航菜单后关闭
      if (this.$store.getters.isPCM) {
        this.changeOpenSlider(true);
      }
    },
  },
};
</script>

<style scoped>
.el-button {
  padding: 2px;
  vertical-align: middle;
  margin: 0 10px 0 10px;
}

.el-icon-s-unfold {
  font-size: 28px;
}

.PCMNav {
  background: whitesmoke;
  position: relative;
}
.PCM_img {
  position: absolute;
  left: 44%;
}
.drawer-bg {
  margin-top: 60px;
  background: #000;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 999;
  backdrop-filter: blur(5px);
}

.closeAside {
  width: 0 !important;
}

.PcMAside {
  margin-top: 60px;
  position: fixed;
  background: #20222a;
  height: 100%;
  width: 201px;
  left: 0;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  transition: all 0.28s;
}

.PcCol {
  width: 100%;
  max-width: 850px;
}

.el-row{
  position: relative;
}
.name{
  position: absolute;
  right: 0;
  font-size: 18px;
  font-weight: 700;
  margin: 0 15px;
  color:#b3b3b3;
}
.name:hover{
  cursor: pointer;
}
</style>