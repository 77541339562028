<template>
  <div
    class="swiper-container"
    :style="this.$store.getters.isPCM ? 'height:40vh' : 'height:60vh'"
  >
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item,index) in swiperImgs" :key="index">
        <img :src="item.url" width="100%" alt="首页轮播图" />
      </div>
    </div>
    <!-- <div class="swiper-scrollbar"></div> -->
    <div class="console">
      <span id="text"></span>
      <div class="console-underscore" id="console">_</div>
    </div>
  </div>
</template>
 
<script>
import { Swiper, Autoplay, Scrollbar, EffectFade } from "swiper";
import "swiper/swiper-bundle.min.css";
import "./../../assets/console.js";

Swiper.use([Autoplay, Scrollbar, EffectFade]);

export default {
  name: "Swiper",
  mounted() {
    this.init();
  },
  data() {
    return {
      swiperImgs: [
        {
          name: "1001015.jpg",
          time: "2021-12-01 00:57",
          size: 1036135,
          type: "FILE",
          path: "static/navImg/",
          url: "https://oss.yaimer.com/static/navImg/1001015.jpg",
        },
        {
          name: "wallhaven-2e2qjm.jpg",
          time: "2021-12-01 00:57",
          size: 3954834,
          type: "FILE",
          path: "static/navImg/",
          url: "https://oss.yaimer.com/static/navImg/wallhaven-2e2qjm.jpg",
        },
        {
          name: "wallhaven-83l6k2.jpg",
          time: "2021-12-01 00:57",
          size: 2303794,
          type: "FILE",
          path: "static/navImg/",
          url: "https://oss.yaimer.com/static/navImg/wallhaven-83l6k2.jpg",
        },
        {
          name: "wallhaven-839kvo.jpg",
          time: "2021-12-01 00:57",
          size: 664921,
          type: "FILE",
          path: "static/navImg/",
          url: "https://oss.yaimer.com/static/navImg/wallhaven-839kvo.jpg",
        },
        {
          name: "wallhaven-dpwyql.jpg",
          time: "2021-12-01 00:58",
          size: 214010,
          type: "FILE",
          path: "static/navImg/",
          url: "https://oss.yaimer.com/static/navImg/wallhaven-dpwyql.jpg",
        },
        {
          name: "wallhaven-g8xpx3.jpg",
          time: "2021-12-01 00:58",
          size: 2874544,
          type: "FILE",
          path: "static/navImg/",
          url: "https://oss.yaimer.com/static/navImg/wallhaven-g8xpx3.jpg",
        },
        {
          name: "wallhaven-j5rdx5.jpg",
          time: "2021-12-01 00:57",
          size: 4213436,
          type: "FILE",
          path: "static/navImg/",
          url: "https://oss.yaimer.com/static/navImg/wallhaven-j5rdx5.jpg",
        },
        {
          name: "wallhaven-kwdxr1.jpg",
          time: "2021-12-01 00:57",
          size: 4103255,
          type: "FILE",
          path: "static/navImg/",
          url: "https://oss.yaimer.com/static/navImg/wallhaven-kwdxr1.jpg",
        },
        {
          name: "wallhaven-mpvqjm.jpg",
          time: "2021-12-01 00:58",
          size: 1693918,
          type: "FILE",
          path: "static/navImg/",
          url: "https://oss.yaimer.com/static/navImg/wallhaven-mpvqjm.jpg",
        },
        {
          name: "wallhaven-z8m75g.jpg",
          time: "2021-12-01 00:57",
          size: 5033464,
          type: "FILE",
          path: "static/navImg/",
          url: "https://oss.yaimer.com/static/navImg/wallhaven-z8m75g.jpg",
        },
      ],
      swiperOption: {
        loop: true,
        autoplay: {
          // delay: 5000,
        },
        effect: "fade",
        fadeEffect: {
          crossFade: true,
        },
      },
    };
  },
  methods: {
    init() {
      let mySwiper = new Swiper(".swiper-container", this.swiperOption);
      mySwiper.el.onmouseover = function () {
        mySwiper.autoplay.stop();
      };
      mySwiper.el.onmouseleave = function () {
        mySwiper.autoplay.start();
      };
    },
  },
};
</script>
 
<style scoped>
.swiper-container {
  position: relative;
  overflow: hidden;
}
.swiper-slide {
  background: cadetblue;
  color: white;
}
img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.console {
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  background: rgb(60 60 60 / 40%);
  z-index: 1;
  text-align: center;
  color: white;
  font-size: 14px;
  line-height: 30px;
  /* background: linear-gradient(to top,#b8c4cb,#f6f6f8); */
}
.hidden {
  opacity: 0;
}

.console-underscore {
  display: inline-block;
  position: relative;
  top: -0.14em;
  left: 10px;
}
</style>